<template>
  <CRow>
    <CCol col="12" sm="5" md="4" lg="4">
      <CCard class="two-fa-container">
        <CCardHeader>
          <h5>{{$t("SETTING_2FA_COMPONENT_TITLE_QR_CODE")}}</h5>
        </CCardHeader>
        <CCardBody>
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <CForm @submit.prevent="handleSubmit(onSubmit)">
              <div class="text-center" v-if="secret2Fa != ''">
                <qrcode-vue :value="qrCode" :size="size" level="H"></qrcode-vue>
              </div>
              <CRow class="mt-3">
                <CCol sm="12">
                  <ValidationProvider :name="$t('SETTING_2FA_COMPONENT_FIELD_NAME_VALIDATE_VERIFY_CODE')" rules="required">
                    <div slot-scope="{ errors }">
                      <CInput :label="$t('SETTING_2FA_COMPONENT_LABEL_VERIFY_CODE')" @keyup.enter="verify" v-model="inputCode" autofocus />
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </div>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow class="btn-verify">
                <CCol col="6">
                  <CButton type="submit" block color="primary" aria-pressed="true">{{$t('SETTING_2FA_COMPONENT_BUTTON_VERIFY')}}</CButton>
                </CCol>
              </CRow>
            </CForm>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import endpoints from '@/constants/endpoints';
import { AUTH_TWOFA_UPDATE } from '@/store/actions/auth';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      secret2Fa: '',
      size: 150,
      meta: 'otpauth://totp/SecretKey?secret=',
      inputCode: '',
    };
  },
  computed: {
    qrCode() {
      return this.meta + this.secret2Fa;
    },
    ...mapState({
      twofaEnableFlag: state => state.auth.twofaEnableFlag,
    }),
  },
  async mounted() {
    if (!this.twofaEnableFlag) {
      try {
        const { data } = await this.$http.get(endpoints.getSecret2Fa);

        this.secret2Fa = data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.verify();
      });
    },
    verify() {
      let params;
      if (this.twofaEnableFlag) {
        params = {
          twofa_code: this.inputCode,
          disable: this.twofaEnableFlag,
        };
      } else {
        params = {
          twofa_secret: this.secret2Fa,
          twofa_code: this.inputCode,
          disable: this.twofaEnableFlag,
        };
      }

      this.$store.dispatch(AUTH_TWOFA_UPDATE, params).then(res => {
        this.$router.push('/admin/profile');
      });
    },
  },
  components: {
    QrcodeVue,
  },
};
</script>

<style lang="scss" scoped>
.two-fa-container {
  .btn-verify {
    justify-content: center;
  }

  .error-msg-wrap {
    .error-msg {
      color: red;
    }
  }
}
</style>
